<template>
  <div>
    <b-row>
      <b-col cols="12">
        <!-- shimmer table -->
        <b-table-simple class="scrollable-element" striped responsive>
          <b-thead>
            <b-tr>
              <b-th v-for="(field, index) in fields" :key="index">{{
                field.label
              }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(n, nindex) in 8" :key="nindex">
              <b-td v-for="(field, index) in fields" :key="index">
                <skeleton-loader-vue
                  type="ract"
                  :height="18"
                  animation="wave"
                  class="cell-width"
                />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <!-- shimmer table complete -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SkeletonLoaderVue from "skeleton-loader-vue"

export default {
  name: "TableLoader",
  components: {
    SkeletonLoaderVue,
  },
  props: {
    fields: { type: Array, default:() => [] },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>
<style>
.cell-width {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}
</style>