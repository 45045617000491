<template>
  <div>
    <div class="ml-50">
      <span class="spanclass mr-1">Per Page :</span>
    <b-form-select
        v-model="localValue"
        :options="options"
        class="clockListdata"
    />
    </div>
  </div>
</template>

<script>
export default {
    name:'Optiondropdown'
   ,
  components: {},
  model: {
    event: "change",
  },
  props: {
    value: {
      type: [Array, String, Number],
      default: "",
    },
    options: {
      type: [Array],
      default() {
        return  [10, 25, 50, 100]
      },
    },
  },

  computed: {

    valueString() {
      return this.multiSelect ? this.value.join(", ") : this.value
    },
    localValue: {
      get() {
        return this.value
      },
      set(data) {
        this.$emit("change", data)
      },
    },
  
  },

}

</script>

<style lang="scss">
@import "../../assets/scss/variables/_variables.scss";
.spanclass {
  font-size: 1.75 * $rem;
  font-weight: $fw500;
  text-transform: capitalize;
  line-height: 23px;
  color: #5e5873;
  vertical-align: middle;
}
</style>